<script setup>
import { onMounted, onUnmounted, ref, nextTick } from 'vue';
import AppDb from '../db/AppDb';
import Thumbnail from './Thumbnail.vue';
import EventBus from '../libs/events/EventBus';
import { gsap } from 'gsap';

const photos = ref(AppDb.photos);
const infos = ref([]);
const cols = ref(null);

const photosRefs = ref([]);
let onMouseEnter = () => {};
let onMouseOut = () => {};
let onShowPhoto = () => {};
let loadedCount = 0;
let tweenCount = 0;
let ready = ref(false);
let touchTimeoutId = null;

const enableMouseEvents = () => {
    onMouseEnter = mouseEnter;
    onMouseOut = mouseOut;
    onShowPhoto = mouseClick;
};

const disableMouseEvents = () => {
    onMouseEnter = () => {};
    onMouseOut = () => {};
    onShowPhoto = () => {};
};

const onPhotoClosed = () => {
    enableMouseEvents();
};

const mouseEnter = (index) => {
    if (window.innerWidth <= 992) return;
    const image = photosRefs.value[index];
    const info = infos.value[index];

    gsap.killTweensOf(image);
    gsap.killTweensOf(info);

    gsap.fromTo(
        image,
        { scale: 1 },
        {
            scale: 1.1,
            duration: 0.25,
            ease: 'power2.out',
        }
    );

    gsap.fromTo(
        info,
        { opacity: 0, y: '0%' },
        {
            opacity: 1,
            y: '-10%',
            delay: 0.25,
            duration: 0.25,
            ease: 'power2.in',
        }
    );
};

const mouseOut = (index) => {
    if (window.innerWidth <= 992) return;
    const image = photosRefs.value[index];
    const info = infos.value[index];

    gsap.killTweensOf(image);
    gsap.killTweensOf(info);

    gsap.fromTo(
        image,
        { scale: 1.1 },
        {
            scale: 1,
            duration: 0.25,
            ease: 'easeOut',
            onComplete: () => {
                image.style.transform = '';
            },
        }
    );

    gsap.to(info, {
        opacity: 0,
        y: '-10%',
        duration: 0.25,
        ease: 'easeOut',
    });
};

const onTouchStart = (index) => {
    touchTimeoutId = setTimeout(() => {
        const info = infos.value[index];
        gsap.fromTo(
            info,
            { y: '0%' },
            {
                opacity: 1,
                y: '-10%',
                delay: 0.25,
                duration: 0.25,
                ease: 'power2',
            }
        );
        clearTimeout(touchTimeoutId);
        touchTimeoutId = null;
    }, 750);
};

const onTouchEnd = (index) => {
    const info = infos.value[index];
    if (touchTimeoutId !== null) {
        clearTimeout(touchTimeoutId);
        touchTimeoutId = null;
    }
    gsap.to(info, {
        opacity: 0,
        y: '-10%',
        delay: 0.25,
        duration: 0.25,
        ease: 'power2.out',
    });
};

const mouseClick = (photo, index) => {
    if (window.innerWidth <= 992) return;
    disableMouseEvents();

    photosRefs.value.forEach((element, i) => {
        if (i !== index) {
            gsap.killTweensOf(element);
            element.style.transform = '';
        }
    });

    const image = photosRefs.value[index];
    const info = infos.value[index];

    gsap.to(image, {
        scale: 1,
        duration: 0.15, // GSAP duration is in seconds, so 150ms is 0.15s
        ease: 'power2.out',
        onComplete: () => {
            image.style.transform = '';
        },
    });
    gsap.to(info, {
        opacity: 0,
        y: '-10%', // GSAP uses 'y' for translateY
        duration: 0.25, // GSAP duration is in seconds, so 250ms is 0.25s
        ease: 'power2',
        onComplete: () => {
            EventBus.emit('show-photo', photo);
        },
    });
};

const tweenCompleted = () => {
    tweenCount++;
    if (tweenCount === photos.value.length) {
        enableMouseEvents();
    }
};

const onTagsSelected = async (tags) => {
    disableMouseEvents();
    tweenCount = 0;
    loadedCount = 0;

    photos.value =
        tags.length > 0 ? AppDb.photos.filter((photo) => tags.some((tag) => photo.tags.includes(tag))) : AppDb.photos;
    cols.value = photos.value.length % 2 === 0 ? 'col-md-12 col-lg-3' : 'col-md-12 col-lg-4';
    await nextTick();
    showPhotos();
};

const showPhotos = () => {
    tweenCount = 0;

    photosRefs.value.forEach((element) => {
        gsap.killTweensOf(element);
        element.style.opacity = 0;
    });

    photosRefs.value.forEach((element, index) => {
        // How can I tween the opacity and the scale in gsap?
        gsap.fromTo(
            element,
            {
                opacity: 0,
                scale: 0.8,
            },
            {
                opacity: 1,
                scale: 1,
                delay: index * 0.06,
                duration: 0.35,
                ease: 'power2.out',
                onComplete: () => tweenCompleted(),
            }
        );
    });
};

const onThumbnailLoaded = () => {
    if (ready.value) return;
    loadedCount++;
    ready.value = loadedCount === photos.value.length;
    if (ready.value) {
        cols.value = photos.value.length % 2 === 0 ? 'col-md-12 col-lg-3' : 'col-md-12 col-lg-4';
        EventBus.emit('thumbnails-loaded');
        showPhotos();
    }
};

onMounted(async () => {
    EventBus.on('photo-closed', onPhotoClosed);
    EventBus.on('tags-selected', onTagsSelected);
    EventBus.on('nav-opened', disableMouseEvents);
    EventBus.on('nav-closed', enableMouseEvents);
});

onUnmounted(() => {
    EventBus.clear('photo-closed', onPhotoClosed);
    EventBus.clear('tags-selected', onTagsSelected);
    EventBus.clear('nav-opened', disableMouseEvents);
    EventBus.clear('nav-closed', enableMouseEvents);
});
</script>

<template>
    <div class="padding-thumbnails app-x-padding text-center">
        <div>
            <img v-show="!ready" class="wait-image" src="/assets/images/Wait.svg" />
            <div v-show="ready" class="row">
                <div
                    ref="photosRefs"
                    :style="(opacity = 0)"
                    :class="cols"
                    v-for="(photo, index) in photos"
                    :key="index"
                >
                    <div class="position-relative d-inline-block">
                        <Thumbnail
                            class="my-2"
                            :url="`/assets/photos/normal/${photo.name}`"
                            :name="photo.name"
                            @click.prevent="onShowPhoto(photo, index)"
                            @loaded="onThumbnailLoaded()"
                            @mouseenter="onMouseEnter(index)"
                            @mouseout="onMouseOut(index)"
                            @touchstart="onTouchStart(index)"
                            @touchend="onTouchEnd(index)"
                        />
                        <span ref="infos" style="opacity: 0" class="thumbnail-info">{{ photo.info }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@media (min-width: 992px) {
    .padding-thumbnails {
        padding-top: 0 !important;
    }

    .thumbnail-info {
        bottom: 1rem;
    }
}
@media (max-width: 991px) {
    .thumbnail-info {
        bottom: 0rem;
    }
}

.thumbnail-info {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    line-height: 3;
    text-align: center;
    pointer-events: none;
    padding-bottom: 0.5rem;
}

.wait-image {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
