<script setup>
import { onMounted, onUnmounted, ref, computed } from 'vue';
import AppDb from '../db/AppDb';
import Photo from './Photo.vue';
import EventBus from '../libs/events/EventBus';

const current = ref(null);
const photo = ref(null);

function onClose() {
    if (current.value === null) return;

    document.removeEventListener('keyup', onKeyup);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('click', onMouseClick);

    current.value = null;
    EventBus.emit('photo-closed');
}

function onShowPhoto(data) {
    document.addEventListener('keyup', onKeyup);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('click', onMouseClick);

    current.value = data;
}

const onKeyup = (event) => {
    switch (event.key) {
        case 'Escape':
            onClose();
            break;
        case 'ArrowLeft':
            current.value = AppDb.prev(current.value);
            break;
        case 'ArrowRight':
            current.value = AppDb.next(current.value);
            break;
    }
};

const photoUrl = computed(() => {
    return current.value ? `/assets/photos/normal/${current.value}` : null;
});

const isLeft = (clientX) => {
    const mid = window.innerWidth / 2;
    return clientX < mid;
};

const isRight = (clientX) => {
    const mid = window.innerWidth / 2;
    return clientX > mid;
};

const onMouseMove = (event) => {
    if (isLeft(event.clientX)) {
        photo.value.$el.classList.remove('mouse-right');
        photo.value.$el.classList.add('mouse-left');
    } else if (isRight(event.clientX)) {
        photo.value.$el.classList.remove('mouse-left');
        photo.value.$el.classList.add('mouse-right');
    }
};

const onMouseClick = (event) => {
    if (isLeft(event.clientX)) {
        current.value = AppDb.prev(current.value);
    } else if (isRight(event.clientX)) {
        current.value = AppDb.next(current.value);
    }
};

onMounted(() => {
    EventBus.on('show-photo', onShowPhoto);
});

onUnmounted(() => {
    EventBus.clear('show-photo', onShowPhoto);
    // Be paranoid!
    document.removeEventListener('keyup', onKeyup);
    document.removeEventListener('click', onMouseClick);
    document.removeEventListener('mousemove', onMouseMove);
});
</script>

<template>
    <div>
        <Photo ref="photo" :data="current" @close-photo="onClose" />
    </div>
</template>

<style scoped>
.mouse-left {
    cursor: url('/assets/images/chevron-left.svg'), auto !important;
}
.mouse-right {
    cursor: url('/assets/images/chevron-right.svg'), auto !important;
}
</style>
