class AppDb {
    constructor() {
        this.__data = null;
    }

    init(data) {
        this.__data = data || {};
    }

    ready() {
        return this.__data !== null;
    }

    prev(item) {
        const index = (this.__data.photos || []).findIndex((d) => d.name === item.name);
        return index === -1
            ? null
            : this.__data.photos[(index + this.__data.photos.length - 1) % this.__data.photos.length];
    }

    next(item) {
        const index = (this.__data.photos || []).findIndex((d) => d.name === item.name);
        return index === -1 ? null : this.__data.photos[(index + 1) % this.__data.photos.length];
    }

    get tags() {
        return this.__data.tags || [];
    }

    get photos() {
        return this.__data.photos || [];
    }
}

export default new AppDb();
