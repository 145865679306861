<script setup>
import { gsap } from 'gsap';
import { onMounted, onUnmounted, ref, nextTick, computed } from 'vue';
import EventBus from '../libs/events/EventBus';
import SearchBar from './SearchBar.vue';
import Content from './Content.vue';
import Utils from '../libs/support/Utils';

const nav = ref(null);
const tagDropdown = ref(null);
const tagDropdownMenu = ref(null);
const contentDropdown = ref(null);

function fadeIn(show) {
    gsap.timeline({
        onStart: () => {
            if (show) nav.value.classList.remove('d-none');
        },
    }).to(nav.value, {
        opacity: 1,
        duration: 0.35,
        ease: 'power2',
    });
}

function fadeOut(hide) {
    tagDropdown.value.classList.remove('show');
    gsap.fromTo(nav.value, {
        opacity: 1,
    }, {
        opacity: 0,
        duration: 0.35,
        ease: 'power2.out',
        onComplete: () => {
            if (hide) nav.value.classList.add('d-none');
        },
    });
    
}

const isMobile = computed(() => Utils.isMobile());

const onThumbnailsLoaded = () => {
    fadeIn();
};

const onShowPhoto = () => {
    fadeOut(true);
};

const onPhotoClosed = () => {
    console.log('photo closed');
    fadeIn(true);
};

const navShown = () => {
    document.body.style.overflow = 'hidden';
    EventBus.emit('nav-opened');
};

const navHidden = () => {
    document.body.style.overflow = null;
    EventBus.emit('nav-closed');
};

const onTagsDropdownShown = () => {
    tagDropdownMenu.value.classList.add('d-flex', 'align-items-center');
    navShown();
};

const onTagsDropdownHide = () => {
    tagDropdownMenu.value.classList.remove('d-flex', 'align-items-center');
    navHidden();
};

const onContentDropdownShown = () => {
    navShown();
};

const onContentDropdownHide = () => {
    navHidden();
};

const onTagsSelected = async (tags) => {
    EventBus.emit('tags-selected', tags);
    await nextTick();
};

onMounted(async () => {
    EventBus.on('thumbnails-loaded', onThumbnailsLoaded);
    EventBus.on('show-photo', onShowPhoto);
    EventBus.on('photo-closed', onPhotoClosed);
    nav.value.style.opacity = 0;
    await nextTick();
    tagDropdown.value.addEventListener('shown.bs.dropdown', onTagsDropdownShown);
    tagDropdown.value.addEventListener('hide.bs.dropdown', onTagsDropdownHide);
    contentDropdown.value.addEventListener('shown.bs.dropdown', onContentDropdownShown);
    contentDropdown.value.addEventListener('hide.bs.dropdown', onContentDropdownHide);
});

onUnmounted(() => {
    EventBus.clear('thumbnails-loaded', onThumbnailsLoaded);
    EventBus.clear('show-photo', onShowPhoto);
    EventBus.clear('photo-closed', onPhotoClosed);
    tagDropdown.value.removeEventListener('shown.bs.dropdown', onTagsDropdownShown);
    tagDropdown.value.removeEventListener('hide.bs.dropdown', onTagsDropdownHide);
    contentDropdown.value.removeEventListener('shown.bs.dropdown', onContentDropdownShown);
    contentDropdown.value.removeEventListener('hide.bs.dropdown', onContentDropdownHide);
});
</script>

<template>
    <div ref="nav" class="bg-white sticky-top py-2 border-bottom" :class="{ 'vsw-100': isMobile }">
        <div class="row app-x-padding align-items-center">
            <div class="col-10 d-flex align-items-center">
                <img src="/assets/images/logo.svg" alt="Kazoompa Portraits" class="navbar-brand nav-logo" />
                <span class="fs-1 mb-0">Kazoompa Portraits</span>
            </div>
            <div class="col-2 d-flex justify-content-end">
                <button ref="tagDropdown" type="button" class="btn dropdown-toggle p-2" data-bs-toggle="dropdown">
                    <i class="fa-solid fa-hashtag"></i>
                </button>
                <ul
                    ref="tagDropdownMenu"
                    class="dropdown-menu dropdown-menu-center rounded-0 border-top"
                    @click.prevent="onPanelClicked"
                >
                    <li><SearchBar @tags-selected="onTagsSelected"></SearchBar></li>
                </ul>
                <button ref="contentDropdown" type="button" class="btn dropdown-toggle p-2" data-bs-toggle="dropdown">
                    <i class="fa-solid fa-bars"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-content rounded-0 border-top">
                    <li><Content></Content></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn:hover {
    border: 1px solid #dddddd;
}

.nav-logo {
    max-height: 2rem;
    padding-right: 0.5rem;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu-content {
    width: 100vw !important;
    height: 100vh !important;
    top: 10% !important;
    overflow-y: auto;
}

.dropdown-menu-center {
    width: 100vw !important;
    height: 4rem !important;
    top: 10% !important;
}
</style>
